import React, { useState, useCallback, useContext } from "react"
import { Stack, Heading } from "@shopify/polaris"
import { navigate } from "gatsby"
import { doc } from 'firebase/firestore';
import { useDocumentData } from "react-firebase-hooks/firestore"
import FirebaseContext from "../providers/firebase"
import getCheckoutProfiles from "../helpers/getCheckoutProfiles"
import CustomizationSelectionCard from "./customizationSelectionCard"

const allCheckout = [
  "shopify_plus",
  "affiliate",
  "partner_test",
  "plus_partner_sandbox",
  "staff",
  "staff_business",
  "custom",
  "unlimited",
]
const Onboarding = (props) => {
  const { rules, customization } = props
  const { firebase, shop, token, host } = useContext(FirebaseContext)
  const [loading, setLoading] = useState(false)
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData,
    }
  )
  console.log("shopData", shopData, shopDataLoading, shopDataError)
  const createOfferHandler = async () => {
    let checkoutProfiles
    try {
      setLoading(true)
      checkoutProfiles = await getCheckoutProfiles(token, shop, host)
      console.log("checkoutProfiles", checkoutProfiles)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
    if (checkoutProfiles && checkoutProfiles.data.checkoutProfiles) {
      /** Created a check for a published checkout profile and divided it into two options for the future
       * if we need to use different behavior */
      if (
        !checkoutProfiles.data.checkoutProfiles.edges.some(
          ({ node }) => node.isPublished
        )
      ) {
        navigate("/app/offers/createCheckoutType", {
          state: { rules },
          replace: true,
        })
      } else {
        navigate("/app/offers/createCheckout", {
          state: { rules },
          replace: true,
        })
      }
    } else {
      navigate("/app/offers/create", {
        state: { rules },
        replace: true,
      })
    }
  }

  const createCartOfferHandler = useCallback(() => {
    navigate("/app/offers/createCartType", {
      state: { rules },
      replace: true,
    })
  }, [rules])

  const createPostPurchaseOfferHandler = useCallback(() => {
    navigate("/app/offers/createPostPurchase", {
      state: { rules },
      replace: true,
    })
  }, [rules])
  const createCustomContentHandler = useCallback(() => {
    navigate("/app/customcontent/createCustomContent", {
      replace: true,
    })
  }, [])

  const createCustomFieldHandler = useCallback(() => {
    navigate("/app/customfield/createCustomField", {
      replace: true,
    })
  }, [])

  const createRewardsBarHandler = useCallback(() => {
    navigate(`/app/onboardingLastStep`, {
      state: {
        backLink: "/app/customizations",
        extensionName: "Rewards Bar",
      },
      replace: true,
    })
  }, [])

  const learnMoreHandler = useCallback(() => {
    window.open(
      `https://help.upsellplus.com/en/article/how-to-add-a-free-shipping-bar-to-your-checkout-ibr8aj/`,
      "_blank"
    )
  }, [])

  const createAddressBlockerHandler = useCallback(() => {
    navigate("/app/addressblocker/createAddressBlocker", {
      replace: true,
    })
  }, [])

  const createHidePaymentMethodsHandler = useCallback(() => {
    navigate("/app/hidepaymentmethods/createHidePaymentMethods", {
      replace: true,
    })
  }, [])

  const createHideDeliveryMethodsHandler = useCallback(() => {
    navigate("/app/hidedeliverymethods/createHideDeliveryMethods", {
      replace: true,
    })
  }, [])

  const createCheckoutBrandingHandler = useCallback(() => {
    navigate("/app/settings/branding", {
      state: {
        backLink: "customizations",
      },
      replace: true,
    })
  }, [])

  

  const REVENUE_SELECTION = customization
    ? [
        {
          title: "Checkout upsell",
          content: "Increase AOV with upsells and cross-sells",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/checkout-upsell-onboarding.png?alt=media&token=4587b28d-ba56-4d86-8c84-a0279b2195cb",
          url: createOfferHandler,
          loading: loading,
        },
      ]
    : [
        {
          title: "Cart upsell",
          content: "Cart drawer, cart page or a popup",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-onboarding.png?alt=media&token=bcee7290-bf57-462b-b053-b96f5f016805",
          url: createCartOfferHandler,
        },
        {
          title: "Checkout upsell",
          content: "Increase AOV with upsells and cross-sells",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/checkout-upsell-onboarding.png?alt=media&token=4587b28d-ba56-4d86-8c84-a0279b2195cb",
          url: createOfferHandler,
          grey:
            shopData &&
            shopData.plan_name &&
            allCheckout.indexOf(shopData.plan_name) === -1,
          loading: loading,
          plusOnly: true,
        },
        {
          title: "Post-purchase upsell",
          content: "Boost AOV after the purchase",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/postpurchase-onboarding.png?alt=media&token=6019fd9e-2c57-46ab-89f5-a2a836a3be34",
          url: createPostPurchaseOfferHandler,
        },
      ]
  const CONTENT_SELECTION = [
    {
      title: "Banner",
      content: "Show info or a warning",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/banner-selection.jpg?alt=media&token=3f7a3ddd-7528-48fd-a1d6-dab7e31fe7bd",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Image",
      content: "Show an image",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/image-selection.jpg?alt=media&token=36b5dfd2-d172-4333-97fc-a222f2889827",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Rewards bar",
      content: "Add a free shipping or free gift progress bar",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/rewards-bar-selection.jpg?alt=media&token=c1a2a683-2c07-4607-9540-052d91a45315",
      url: createRewardsBarHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
      learnMore: learnMoreHandler,
    },
    {
      title: "Payment icons",
      content: "Show payment icons to instill trust",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/payment-icons-selection.jpg?alt=media&token=d9c0fd5e-c749-49df-9588-8839f80660d1",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Trust badge",
      content: "Add trust badges",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/trust-badge-selection.jpg?alt=media&token=7be735e3-b60b-4fc0-bd17-520b7bbce66f",
      url: createCustomContentHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
    {
      title: "Gift note",
      content: "Give customers the option to leave a gift note",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/gift-note-selection.jpg?alt=media&token=3e667ef0-cd28-4169-a7f2-1c7d30c3385e",
      url: createCustomFieldHandler,
      grey:
        shopData &&
        shopData.plan_name &&
        allCheckout.indexOf(shopData.plan_name) === -1,
    },
  ]

  const PAYMENT_AND_DELIVERY_SELECTION = [
    {
      title: "Hide payment methods",
      content: "Hide payment methods",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/hide-payment-methods.jpg?alt=media&token=ec44e095-5762-4e37-b154-f6c263f36ba0",
      url: createHidePaymentMethodsHandler,
    },
    {
      title: "Hide delivery methods",
      content: "Hide delivery methods",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/hide-delivery-methods-selection.jpg?alt=media&token=d23fa9a5-307f-4ae5-a82d-3dde689d421e",
      url: createHideDeliveryMethodsHandler,
    },
    {
      title: "Address validator",
      content: "Validate address fields and block checkout if invalid",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/address-validator-selection.jpg?alt=media&token=40bb7168-5290-4d62-bf18-3ded0441dfef",
      url: createAddressBlockerHandler,
    },
  ]

  const BRANDING_AND_STYLE_SELECTION = [
    {
      title: "Branding and styling",
      content: "Control the colors, fonts and styling of your checkout",
      image:
        "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/branding-and-styleing-selection.jpg?alt=media&token=83b95843-9b0a-4b2f-885c-0ad5b9b3dc62",
      url: createCheckoutBrandingHandler,
    },
  ]

  return (
    <div>
      {!customization && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <div style={{ paddingBottom: "15px" }}>
              <Heading>Revenue</Heading>
            </div>
            <Stack>
              {REVENUE_SELECTION.map((item, index) => {
                return <CustomizationSelectionCard key={index} {...item} />
              })}
            </Stack>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ padding: "15px 0" }}>
            <Heading>Content</Heading>
          </div>
          <Stack>
            {CONTENT_SELECTION.slice(0, 3).map((item, index) => {
              return <CustomizationSelectionCard key={index} {...item} />
            })}
          </Stack>
          <div style={{ paddingTop: "15px" }}>
            <Stack>
              {CONTENT_SELECTION.slice(-3).map((item, index) => {
                return <CustomizationSelectionCard key={index} {...item} />
              })}
            </Stack>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "100px",
        }}
      >
        <div>
          <div style={{ padding: "15px 0" }}>
            <Heading>Payment and delivery</Heading>
          </div>
          <Stack>
            {PAYMENT_AND_DELIVERY_SELECTION.map((item, index) => {
              return <CustomizationSelectionCard key={index} {...item} />
            })}
          </Stack>
        </div>
      </div>
      {
      <div style={{ paddingBottom: "100px" }}>
        <div>
          <div style={{ padding: "15px 0" }}>
            <Heading>Branding and style</Heading>
          </div>
          <Stack>
            <Stack.Item>
              {BRANDING_AND_STYLE_SELECTION.map((item, index) => {
                return <CustomizationSelectionCard key={index} {...item} />
              })}
            </Stack.Item>
            <Stack.Item fill>
              <div></div>
            </Stack.Item>
          </Stack>
        </div>
      </div>
      }
    </div>
  )
}

export default Onboarding
